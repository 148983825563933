import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'urql';

import notification from '../../notification';

import type { Binding } from '@gvdp/common/Binding';

import { Layout } from '../../components/_layout';

type Form = {
    name: string;
};

export const ManageBinding: React.FC = () => {
    const navigate = useNavigate();
    const {
        handleSubmit,
        register,
        setValue,
        formState: { errors }
    } = useForm<Form>();

    const { id } = useParams<{ id: string }>();

    const [query] = useQuery({
        query: /* GraphQL */ `
            query ($id: ID!) {
                binding(id: $id) {
                    name
                }
            }
        `,
        variables: { id },
        pause: !id
    });

    useEffect(() => {
        if (!query.fetching && query.data && query.data.binding) {
            const data = query.data.binding as Binding;
            setValue('name', data.name, { shouldValidate: true, shouldDirty: true });
        }
    }, [query, setValue]);

    const [, manageBinding] = useMutation(/* GraphQL */ `
        mutation ($id: ID, $name: String!) {
            manageBinding(id: $id, name: $name)
        }
    `);

    const onSubmit = async (input: Form) => {
        const result = await manageBinding({ id, ...input });

        if (result.error) {
            notification.error(result.error);
        } else {
            if (!id) {
                navigate(`/bindings/manage/${result.data.manageBinding}`, { replace: true });
            }

            notification.success('Rilegatura salvata con successo');
        }
    };

    return (
        <Layout title="Gestisci rilegatura">
            <h2>Gestisci rilegatura</h2>
            <span className="italic font-bold text-xs">* Campi obbligatori</span>

            <form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
                <label className="font-bold" htmlFor="name">
                    Nome *
                </label>
                <input {...register('name', { required: true })} placeholder="Nome" className="input mt-2" />
                {errors.name && <div className="input-error">Il nome è obbligatorio</div>}

                <button type="submit" className="button vdp-green w-full mt-4">
                    Salva
                </button>
            </form>
        </Layout>
    );
};
