import { ReactNode, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import { useAuth } from '../contexts/auth';

import logo from '../assets/vdp_logo_2020_hor.png';

type LayoutProps = {
    title?: string;
    children: ReactNode;
};

export const Layout: React.FC<LayoutProps> = (props: LayoutProps) => {
    const { logout } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        document.title = `${props.title} - Vicolo del Pavone`;
    });

    const doLogout = async (): Promise<void> => {
        if (logout) {
            logout();
            navigate('/login');
        }
    };

    return (
        <div className="w-2/3 mx-auto">
            <nav className="border-b-2 border-gray-700">
                <ul className="flex h-full">
                    <li className="flex-grow">
                        <Link to="/" className="w-full h-full p-2 flex items-center justify-center">
                            <img src={logo} className="w-32" alt="Vicolo del Pavone" title="Vicolo del Pavone" />
                        </Link>
                    </li>
                    <li className="flex-grow">
                        <Link to="/categories" className="px-2 py-6 flex flex-col items-center justify-center hover:bg-vdp-beige">
                            <i className="las la-archive la-2x mr-1" />
                            Categorie
                        </Link>
                    </li>
                    <li className="flex-grow">
                        <Link to="/bindings" className="px-2 py-6 flex flex-col items-center justify-center hover:bg-vdp-beige">
                            <i className="las la-barcode la-2x mr-1" />
                            Rilegature
                        </Link>
                    </li>
                    <li className="flex-grow">
                        <Link to="/authors" className="px-2 py-6 flex flex-col items-center justify-center hover:bg-vdp-beige">
                            <i className="las la-pen-nib la-2x mr-1" />
                            Autori
                        </Link>
                    </li>
                    <li className="flex-grow">
                        <Link to="/books" className="px-2 py-6 flex flex-col items-center justify-center hover:bg-vdp-beige">
                            <i className="las la-book la-2x mr-1" />
                            Libri
                        </Link>
                    </li>
                    <li className="flex-grow">
                        <Link to="/coupons" className="px-2 py-6 flex flex-col items-center justify-center hover:bg-vdp-beige">
                            <i className="las la-ticket-alt la-2x mr-1" />
                            Coupons
                        </Link>
                    </li>
                    <li className="flex-grow">
                        <Link to="/shippings" className="px-2 py-6 flex flex-col items-center justify-center hover:bg-vdp-beige">
                            <i className="las la-truck la-2x mr-1" />
                            Spese di spedizione
                        </Link>
                    </li>
                    <li className="flex-grow">
                        <Link to="/news" className="px-2 py-6 flex flex-col items-center justify-center hover:bg-vdp-beige">
                            <i className="las la-newspaper la-2x mr-1" />
                            News
                        </Link>
                    </li>
                    <li className="flex-grow">
                        <Link to="/users" className="px-2 py-6 flex flex-col items-center justify-center hover:bg-vdp-beige">
                            <i className="las la-users la-2x mr-1" />
                            Utenti
                        </Link>
                    </li>
                    <li className="flex-grow">
                        <Link to="/orders" className="px-2 py-6 flex flex-col items-center justify-center hover:bg-vdp-beige">
                            <i className="las la-shopping-cart la-2x mr-1" />
                            Ordini
                        </Link>
                    </li>
                    <li className="flex-grow">
                        <button className="px-2 py-6 flex flex-col items-center justify-center hover:bg-vdp-beige" onClick={() => doLogout()}>
                            <i className="las la-sign-out-alt la-2x mr-1" />
                            Logout
                        </button>
                    </li>
                </ul>
            </nav>

            <section className="mt-4">{props.children}</section>

            <footer className="border-t-2 border-vdp-red mt-8">
                <div className="py-2">© 1984 - 2021 Vicolo del Pavone - Art &amp; Coop Società Cooperativa</div>
                <small>
                    <i>The empty vessel makes the loudest sound</i> - William Shakespeare
                </small>
            </footer>
        </div>
    );
};
