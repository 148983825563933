import { Layout } from '../components/_layout';

export const Home: React.FC = () => {
    return (
        <Layout title="Benvenuti">
            <h1 className="text-6xl">Benvenuti</h1>
            <div className="mt-4">Questa è la nuova area amministrativa di Vicolo del Pavone. Usa il menu in alto per scegliere quale area gestire.</div>
        </Layout>
    );
};
