import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useMutation, useQuery } from 'urql';

import notification from '../../notification';

import type { Category } from '@gvdp/common/Category';

import { Layout } from '../../components/_layout';
import { Select } from '../../components/Select';

export const CategoryList: React.FC = () => {
    const limit = 20;
    const [cursor, setCursor] = useState(0);
    const [orderBy, setOrderBy] = useState('order_ASC');

    const [query] = useQuery({
        query: /* GraphQL */ `
            query($limit: Int!, $cursor: Int, $orderBy: CategoryOrderByInput) {
                categories(limit: $limit, cursor: $cursor, orderBy: $orderBy) {
                    edges {
                        id
                        name
                        order
                    }
                    pageInfo {
                        hasNextPage
                        endCursor
                    }
                }
            }
        `,
        variables: { limit, cursor, orderBy }
    });

    if (query.error) {
        notification.error(query.error);
    }

    const fetch = (cur: number, order?: string) => {
        setCursor(+(cur !== 0) && (cur || cursor));
        if (order) {
            setOrderBy(order);
        }
    };

    const [, deleteCategory] = useMutation(/* GraphQL */ `
        mutation($id: ID!) {
            deleteCategory(id: $id) {
                id
            }
        }
    `);

    const doDelete = async (id: string) => {
        if (window.confirm('Sei sicuro di voler eliminare questa categoria?')) {
            const result = await deleteCategory({ id });

            if (result.error) {
                notification.error(result.error);
            } else {
                notification.success('Categoria eliminata con successo');
            }
        }
    };

    return (
        <Layout title="Elenco categorie">
            <div className="flex">
                <div className="flex items-center">
                    <h3>Categorie</h3>
                    <Link to="/categories/manage" className="ml-2 text-3xl text-vdp-green">
                        <i className="las la-plus-circle" />
                    </Link>
                </div>

                <div className="w-64 ml-auto">
                    <div className="text-right text-sm mb-1">Ordina categorie per</div>
                    <Select onChange={val => fetch(cursor, val.target.value)}>
                        <option value="order_ASC">Ordine crescente</option>
                        <option value="order_DESC">Ordine decrescente</option>
                        <option value="name_ASC">Nome A-Z</option>
                        <option value="name_DESC">Nome Z-A</option>
                    </Select>
                </div>
            </div>

            {query.fetching && (
                <div className="p-4 flex justify-center">
                    <span className="text-xl">Caricamento...</span>
                </div>
            )}

            {query.error && (
                <div className="p-4 flex justify-center">
                    <span className="text-xl text-vdp-red">Si è verificato un errore nel gestire la richiesta</span>
                </div>
            )}
            {query.data && (
                <>
                    <table className="mt-4 table-auto w-full">
                        <thead>
                            <tr>
                                <th className="w-12 text-xl font-semibold">Ord.</th>
                                <th className="px-4 py-2 text-xl font-semibold text-left">Nome</th>
                                <th className="w-1/5 px-4 py-2 text-xl font-semibold">Azioni</th>
                            </tr>
                        </thead>
                        <tbody>
                            {query.data.categories.edges.map((c: Category) => (
                                <tr key={c.id} className="odd:bg-vdp-beige">
                                    <td className="p-4">{c.order}</td>
                                    <td className="p-4">{c.name}</td>
                                    <td className="p-4 border-l border-gray-300 flex">
                                        <Link to={`/categories/manage/${c.id}`} className="flex-1 button small vdp-green">
                                            Modifica
                                        </Link>
                                        &nbsp;
                                        <button className="flex-1 button small vdp-red" onClick={() => doDelete(c.id as string)}>
                                            Elimina
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <div className="mt-8 flex justify-center">
                        <div className="border border-gray-700 rounded flex">
                            {cursor > 0 && (
                                <button className="block w-32 p-4 text-center font-semibold" onClick={() => fetch(cursor - limit)}>
                                    INDIETRO
                                </button>
                            )}

                            {query.data.categories.pageInfo.hasNextPage && (
                                <button
                                    className="even:border-l border-gray-700 block w-32 p-4 text-center font-semibold"
                                    onClick={() => fetch(query.data.categories.pageInfo.endCursor)}
                                >
                                    AVANTI
                                </button>
                            )}
                        </div>
                    </div>
                </>
            )}
        </Layout>
    );
};
