import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'urql';

import notification from '../../notification';

import type { Shipping } from '@gvdp/common/Shipping';

import { Layout } from '../../components/_layout';

type Form = {
    method: string;
    description?: string;
    shippingprice: number;
    packageprice: number;
    freeShippingItemTreshold?: number;
    freeShippingPriceTreshold?: number;
};

export const ManageShipping: React.FC = () => {
    const navigate = useNavigate();
    const {
        handleSubmit,
        register,
        setValue,
        formState: { errors }
    } = useForm<Form>();

    const { id } = useParams<{ id: string }>();

    const [query] = useQuery({
        query: /* GraphQL */ `
            query ($id: ID!) {
                shippingADView(id: $id) {
                    method
                    description
                    shippingprice
                    packageprice
                    freeShippingItemTreshold
                    freeShippingPriceTreshold
                }
            }
        `,
        variables: { id },
        pause: !id
    });

    useEffect(() => {
        if (!query.fetching && query.data && query.data.shippingADView) {
            const data = query.data.shippingADView as Shipping;
            setValue('method', data.method, { shouldValidate: true, shouldDirty: true });
            setValue('description', data.description, { shouldValidate: true, shouldDirty: true });
            setValue('shippingprice', data.shippingprice, { shouldValidate: true, shouldDirty: true });
            setValue('packageprice', data.packageprice, { shouldValidate: true, shouldDirty: true });
            setValue('freeShippingItemTreshold', data.freeShippingItemTreshold, { shouldValidate: true, shouldDirty: true });
            setValue('freeShippingPriceTreshold', data.freeShippingPriceTreshold, { shouldValidate: true, shouldDirty: true });
        }
    }, [query, setValue]);

    const [, manageShipping] = useMutation(/* GraphQL */ `
        mutation ($id: ID, $input: ShippingInput!) {
            manageShipping(id: $id, input: $input)
        }
    `);

    const onSubmit = async (input: Form) => {
        if (!input.description) {
            delete input.description;
        }

        // because html form is always a string
        input.shippingprice = +input.shippingprice;
        input.packageprice = +input.packageprice;
        if (input.freeShippingItemTreshold) {
            input.freeShippingItemTreshold = +input.freeShippingItemTreshold;
        } else {
            delete input.freeShippingItemTreshold;
        }
        if (input.freeShippingPriceTreshold) {
            input.freeShippingPriceTreshold = +input.freeShippingPriceTreshold;
        } else {
            delete input.freeShippingPriceTreshold;
        }

        const result = await manageShipping({ id, input });

        if (result.error) {
            notification.error(result.error);
        } else {
            if (!id) {
                navigate(`/shippings/manage/${result.data.manageShipping}`, { replace: true });
            }

            notification.success('Spesa di spedizione salvata con successo!');
        }
    };

    return (
        <Layout title="Gestisci spesa di spedizione">
            <h2>Gestisci spesa di spedizione</h2>
            <span className="italic font-bold text-xs">* Campi obbligatori</span>

            <form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
                <div className="flex">
                    <label className="font-bold w-2/12 pr-4 flex justify-end items-center" htmlFor="shippingprice">
                        Metodo *
                    </label>
                    <div className="flex-1">
                        <input {...register('method', { required: true })} placeholder="Metodo di spedizione" className="input" />
                        {errors.shippingprice && <div className="input-error">Il metodo di spedizione è obbligatorio</div>}
                    </div>
                </div>
                <div className="flex mt-4">
                    <label className="font-bold w-2/12 pr-4 flex justify-end items-center" htmlFor="shippingprice">
                        Descrizione
                    </label>
                    <div className="flex-1">
                        <input {...register('description')} placeholder="Descrizione" className="input" />
                    </div>
                </div>
                <div className="flex mt-4">
                    <label className="font-bold w-2/12 pr-4 flex justify-end items-center" htmlFor="shippingprice">
                        Spese di spedizione *
                    </label>
                    <div className="flex-1">
                        <input
                            {...register('shippingprice', {
                                required: 'Questo prezzo è obbligatorio',
                                min: { value: 0, message: 'Il prezzo deve essere >= a zero' }
                            })}
                            placeholder="Spese di spedizione"
                            className="input"
                            type="number"
                            min="0"
                            step="0.1"
                        />
                        {errors.shippingprice && <div className="input-error">{errors.shippingprice.message}</div>}
                    </div>
                </div>
                <div className="flex mt-4">
                    <label className="font-bold w-2/12 pr-4 flex items-center text-right" htmlFor="packageprice">
                        Spese per impacchettamento *
                    </label>
                    <div className="flex-1">
                        <input
                            {...register('packageprice', {
                                required: 'Questo prezzo è obbligatorio',
                                min: { value: 0, message: 'Il prezzo deve essere >= a zero' }
                            })}
                            placeholder="Spese per impacchettamento"
                            className="input"
                            type="number"
                            min="0"
                            step="0.1"
                        />
                        {errors.packageprice && <div className="input-error">{errors.packageprice.message}</div>}
                    </div>
                </div>
                <div className="flex mt-4">
                    <label className="font-bold w-2/12 pr-4 flex items-center text-right" htmlFor="freeShippingItemTreshold">
                        Soglia n° prodotti per spedizione gratis
                    </label>
                    <div className="flex-1">
                        <input
                            {...register('freeShippingItemTreshold', { min: 1 })}
                            placeholder="Soglia n° prodotti per spedizione gratis"
                            className="input"
                            type="number"
                            min="0"
                            step="1"
                        />
                        {errors.freeShippingItemTreshold && <div className="input-error">La soglia non può essere zero</div>}
                    </div>
                </div>
                <div className="flex mt-4">
                    <label className="font-bold w-2/12 pr-4 flex items-center text-right" htmlFor="freeShippingPriceTreshold">
                        Soglia prezzo per spedizione gratis
                    </label>
                    <div className="flex-1">
                        <input
                            {...register('freeShippingPriceTreshold', { min: 1 })}
                            placeholder="Soglia prezzo per spedizione gratis"
                            className="input"
                            type="number"
                            min="0"
                            step="0.1"
                        />
                        {errors.freeShippingPriceTreshold && <div className="input-error">La soglia non può essere zero</div>}
                    </div>
                </div>

                <button type="submit" className="button vdp-green w-full mt-4">
                    Salva
                </button>
            </form>
        </Layout>
    );
};
