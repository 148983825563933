import { useParams } from 'react-router-dom';
import { useQuery } from 'urql';

import notification from '../../notification';

import { Layout } from '../../components/_layout';
import type { Address } from '@gvdp/common/Profile';

export const ViewUser: React.FC = () => {
    const { id } = useParams<{ id: string }>();

    const [query] = useQuery({
        query: /* GraphQL */ `
            query($uid: ID!) {
                profileADView(firebaseUid: $uid) {
                    email
                    completename
                    invoiceInfo {
                        businessName
                        vatId
                        CF
                    }
                    addresses {
                        completeAddress
                        isInvoicingAddress
                        isDefaultShippingAddress
                        consigneeName
                        phone
                    }
                }
            }
        `,
        variables: { uid: id },
        pause: !id
    });

    if (query.error) {
        notification.error(query.error);
    }

    return (
        <Layout title="Dettagli utente">
            <h2>Dettagli utente</h2>

            {query.fetching && (
                <div className="p-4 flex justify-center">
                    <span className="text-xl">Caricamento...</span>
                </div>
            )}

            {query.error && (
                <div className="p-4 flex justify-center">
                    <span className="text-xl text-vdp-red">Si è verificato un errore nel gestire la richiesta</span>
                </div>
            )}
            {query.data && (
                <>
                    <h4>{query.data.profileADView.completename}</h4>
                    Email: {query.data.profileADView.email}
                    {query.data.profileADView.invoiceInfo && (
                        <div className="mt-4">
                            <h2 className="text-2xl">Info per fatturazione</h2>
                            {query.data.profileADView.invoiceInfo.businessName && (
                                <>
                                    Società: {query.data.profileADView.invoiceInfo.businessName}
                                    <br />
                                </>
                            )}
                            {query.data.profileADView.invoiceInfo.vatId && (
                                <>
                                    P.IVA: {query.data.profileADView.invoiceInfo.vatId}
                                    <br />
                                </>
                            )}
                            {query.data.profileADView.invoiceInfo.CF && <>CF: {query.data.profileADView.invoiceInfo.CF}</>}
                        </div>
                    )}
                    {query.data.profileADView.addresses && (
                        <div className="mt-4 flex">
                            {query.data.profileADView.addresses.map((a: Address, i: number) => (
                                <div key={i} className="border px-4 py-2 mr-1">
                                    <h2 className="text-2xl">Indirizzo {i + 1}</h2>
                                    <b>{a.completeAddress}</b>
                                    <br />
                                    Indirizzo di fatturazione?&nbsp;
                                    <b>{a.isInvoicingAddress ? 'SI' : 'NO'}</b>
                                    <br />
                                    Indirizzo di consegna principale?&nbsp;
                                    <b>{a.isDefaultShippingAddress ? 'SI' : 'NO'}</b>
                                    <br />
                                    {a.consigneeName && (
                                        <>
                                            Consegnare a: {a.consigneeName}
                                            <br />
                                        </>
                                    )}
                                    {a.phone && (
                                        <>
                                            Telefono: {a.phone}
                                            <br />
                                        </>
                                    )}
                                </div>
                            ))}
                        </div>
                    )}
                </>
            )}
        </Layout>
    );
};
