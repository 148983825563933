import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseApp = initializeApp({
    apiKey: 'AIzaSyCbC4ncBZqo-3cuW7ZuEtCkxxJxZ9SAv4Y',
    authDomain: 'gvdp-265411.firebaseapp.com',
    databaseURL: 'https://gvdp-265411.firebaseio.com',
    projectId: 'gvdp-265411',
    storageBucket: 'gvdp-265411.appspot.com',
    messagingSenderId: '128418704483',
    appId: '1:128418704483:web:0a8e668a8d69e91ee7dc56'
});

const auth = getAuth(firebaseApp);
export default auth;
