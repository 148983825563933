import { createRef, forwardRef } from 'react';
import DP, { ReactDatePickerProps, registerLocale } from 'react-datepicker';
import it from 'date-fns/locale/it';

registerLocale('it', it);

import 'react-datepicker/dist/react-datepicker.css';

type Props = { value?: string; onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void };

export const DatePicker: React.FC<ReactDatePickerProps> = (props: ReactDatePickerProps) => {
    const ref = createRef<HTMLButtonElement>();

    // eslint-disable-next-line react/display-name
    const CustomDateInput = forwardRef<HTMLButtonElement, Props>(({ onClick, value }: Props, ref) => (
        <button type="button" ref={ref} onClick={onClick} className="flex input items-center">
            {value}
            <i className="las la-calendar  ml-2" />
        </button>
    ));

    return <DP {...props} customInput={<CustomDateInput ref={ref} />} locale="it" dateFormat="dd/MM/yyyy" />;
};
