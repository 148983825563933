import { useState } from 'react';
import { useMutation, useQuery } from 'urql';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

import notification from '../../notification';

import type { Coupon } from '@gvdp/common/Coupon';

import { Layout } from '../../components/_layout';
import { Select } from '../../components/Select';

export const CouponList: React.FC = () => {
    const limit = 20;
    const [cursor, setCursor] = useState(0);
    const [orderBy, setOrderBy] = useState('code_ASC');

    const [query] = useQuery({
        query: /* GraphQL */ `
            query($limit: Int!, $cursor: Int, $orderBy: CouponOrderByInput) {
                coupons(limit: $limit, cursor: $cursor, orderBy: $orderBy) {
                    edges {
                        id
                        code
                        discount
                        freeShipping
                        itemThreshold
                        oneShot
                        expiration
                    }
                    pageInfo {
                        hasNextPage
                        endCursor
                    }
                }
            }
        `,
        variables: { limit, cursor, orderBy }
    });

    if (query.error) {
        notification.error(query.error);
    }

    const fetch = (cur: number, order?: string) => {
        setCursor(+(cur !== 0) && (cur || cursor));
        if (order) {
            setOrderBy(order);
        }

        window.scrollTo(0, 0);
    };

    const [, deleteCoupon] = useMutation(/* GraphQL */ `
        mutation($id: ID!) {
            deleteCoupon(id: $id) {
                id
            }
        }
    `);

    const doDelete = async (id: string) => {
        if (window.confirm('Sei sicuro di voler eliminare questo coupon?')) {
            const result = await deleteCoupon({ id });

            if (result.error) {
                notification.error(result.error);
            } else {
                notification.success('Coupon eliminato con successo');
            }
        }
    };

    return (
        <Layout title="Elenco coupons">
            <div className="flex">
                <div className="flex items-center">
                    <h3>Coupons</h3>
                    <Link to="/coupons/add" className="ml-2 text-3xl text-vdp-green">
                        <i className="las la-plus-circle" />
                    </Link>
                </div>

                <div className="w-64 ml-auto">
                    <div className="text-right text-sm mb-1">Ordina coupons per</div>
                    <Select onChange={val => fetch(cursor, val.target.value)}>
                        <option value="code_ASC">Codice A-Z</option>
                        <option value="code_DESC">Codice Z-A</option>
                    </Select>
                </div>
            </div>

            {query.fetching && (
                <div className="p-4 flex justify-center">
                    <span className="text-xl">Caricamento...</span>
                </div>
            )}

            {query.error && (
                <div className="p-4 flex justify-center">
                    <span className="text-xl text-vdp-red">Si è verificato un errore nel gestire la richiesta</span>
                </div>
            )}
            {query.data && (
                <>
                    <table className="mt-4 table-auto w-full">
                        <thead>
                            <tr>
                                <th className="w-12 font-semibold">#</th>
                                <th className="px-4 py-2 font-semibold text-left">Codice</th>
                                <th className="px-4 py-2 font-semibold text-left">% Sconto</th>
                                <th className="px-4 py-2 font-semibold text-left">Spedizione gratuita</th>
                                <th className="px-4 py-2 font-semibold text-left">Limite minimo di oggetti</th>
                                <th className="px-4 py-2 font-semibold text-left">Solo una volta</th>
                                <th className="px-4 py-2 font-semibold text-left">Data scadenza</th>
                                <th className="w-1/12 px-4 py-2 font-semibold">Azioni</th>
                            </tr>
                        </thead>
                        <tbody>
                            {query.data.coupons.edges.map((c: Coupon, i: number) => (
                                <tr key={c.id} className="odd:bg-vdp-beige">
                                    <td className="p-2 text-center">{cursor + i + 1}</td>
                                    <td className="p-4">{c.code}</td>
                                    <td className="p-4">{c.discount}</td>
                                    <td className="p-4">{c.freeShipping ? 'SI' : 'NO'}</td>
                                    <td className="p-4">{c.itemThreshold || 'Nessuno'}</td>
                                    <td className="p-4">{c.oneShot ? 'SI' : 'NO'}</td>
                                    <td className="p-4">{dayjs(c.expiration).format('DD/MM/YYYY')}</td>
                                    <td className="p-4 border-l border-gray-300">
                                        <button className="flex-1 button small vdp-red" onClick={() => doDelete(c.id as string)}>
                                            Elimina
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <div className="mt-8 flex justify-center">
                        <div className="border border-gray-700 rounded flex">
                            {cursor > 0 && (
                                <button className="block w-32 p-4 text-center font-semibold" onClick={() => fetch(cursor - limit)}>
                                    INDIETRO
                                </button>
                            )}

                            {query.data.coupons.pageInfo.hasNextPage && (
                                <button
                                    className="even:border-l border-gray-700 block w-32 p-4 text-center font-semibold"
                                    onClick={() => fetch(query.data.coupons.pageInfo.endCursor)}
                                >
                                    AVANTI
                                </button>
                            )}
                        </div>
                    </div>
                </>
            )}
        </Layout>
    );
};
