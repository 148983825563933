import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'urql';

import notification from '../../notification';

import { Layout } from '../../components/_layout';
import { DatePicker } from '../../components/Datepicker';

type Form = {
    code: string;
    discount: number;
    expiration: Date;
    freeShipping?: boolean;
    itemThreshold?: number;
    oneShot?: boolean;
};

export const AddCoupon: React.FC = () => {
    const navigate = useNavigate();
    const {
        handleSubmit,
        register,
        control,
        formState: { errors }
    } = useForm<Form>();

    const [, addCoupon] = useMutation(/* GraphQL */ `
        mutation ($input: CouponInput!) {
            addCoupon(input: $input)
        }
    `);

    const onSubmit = async (input: Form) => {
        // because html form is always a string
        input.discount = +input.discount;
        if (input.itemThreshold) {
            input.itemThreshold = +input.itemThreshold;
        }

        if (!input.itemThreshold) {
            delete input.itemThreshold;
        }

        if (!input.freeShipping) {
            delete input.freeShipping;
        }

        if (!input.oneShot) {
            delete input.oneShot;
        }

        const result = await addCoupon({ input });

        if (result.error) {
            notification.error(result.error);
        } else {
            notification.success('Coupon aggiunto con successo!');
            navigate('/coupons');
        }
    };

    return (
        <Layout title="Aggiungi coupon">
            <h2>Aggiungi coupon</h2>
            <span className="italic font-bold text-xs">* Campi obbligatori</span>

            <form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
                <div className="flex">
                    <label className="font-bold w-2/12 pr-4 flex justify-end items-center" htmlFor="code">
                        Codice *
                    </label>
                    <div className="flex-1">
                        <input {...register('code', { required: true })} placeholder="Nome" className="input" />
                        {errors.code && <div className="input-error">Il codice è obbligatorio</div>}
                    </div>
                </div>
                <div className="flex mt-4">
                    <label className="font-bold w-2/12 pr-4 flex justify-end items-center" htmlFor="discount">
                        % Sconto *
                    </label>
                    <div className="flex-1">
                        <input
                            {...register('discount', {
                                required: 'Lo sconto è obbligatorio',
                                min: { value: 0, message: 'Lo sconto deve essere >= a zero' },
                                max: { value: 99, message: 'Lo sconto deve essere <= 99' }
                            })}
                            placeholder="% Sconto"
                            className="input"
                            type="number"
                            min="0"
                            max="99"
                            step="1"
                        />
                        {errors.discount && <div className="input-error">{errors.discount.message}</div>}
                    </div>
                </div>
                <div className="flex mt-4">
                    <label className="font-bold w-2/12 pr-4 flex justify-end items-center" htmlFor="freeShipping">
                        Spedizione gratuita
                    </label>
                    <div className="flex-1 flex items-center">
                        <input {...register('freeShipping')} type="checkbox" />
                    </div>
                </div>
                <div className="flex mt-4">
                    <label className="font-bold w-2/12 pr-4 flex justify-end items-center" htmlFor="itemThreshold">
                        Quantità massima
                    </label>
                    <div className="flex-1">
                        <input
                            {...register('itemThreshold', {
                                min: { value: 1, message: 'La quantita massima deve essere almeno di 1' }
                            })}
                            placeholder="Quantità massima"
                            className="input"
                            type="number"
                            min="1"
                            step="1"
                        />
                        {errors.itemThreshold && <div className="input-error">{errors.itemThreshold.message}</div>}
                    </div>
                </div>
                <div className="flex mt-4">
                    <label className="font-bold w-2/12 pr-4 flex justify-end items-center" htmlFor="oneShot">
                        Solo una volta
                    </label>
                    <div className="flex-1 flex items-center">
                        <input {...register('oneShot')} type="checkbox" />
                    </div>
                </div>
                <div className="flex mt-4">
                    <label className="font-bold w-2/12 pr-4 flex justify-end items-center" htmlFor="expiration">
                        Scadenza *
                    </label>
                    <div className="flex-1">
                        <Controller
                            control={control}
                            name="expiration"
                            rules={{ required: true }}
                            defaultValue={new Date()}
                            render={({ field }) => <DatePicker onChange={field.onChange} onBlur={field.onBlur} selected={field.value} />}
                        />
                        {errors.expiration && <div className="input-error">La scadenza è obbligatoria</div>}
                    </div>
                </div>

                <button type="submit" className="button vdp-green w-full mt-4">
                    Salva
                </button>
            </form>
        </Layout>
    );
};
