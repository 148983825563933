import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'urql';

import notification from '../../notification';

import type { Category } from '@gvdp/common/Category';

import { Layout } from '../../components/_layout';

type Form = {
    order: number;
    name: string;
    description?: string;
};

export const ManageCategory: React.FC = () => {
    const navigate = useNavigate();
    const {
        handleSubmit,
        register,
        setValue,
        formState: { errors }
    } = useForm<Form>();

    const { id } = useParams<{ id: string }>();

    const [query] = useQuery({
        query: /* GraphQL */ `
            query ($id: ID!) {
                categoryADView(id: $id) {
                    name
                    order
                    description
                }
            }
        `,
        variables: { id },
        pause: !id
    });

    useEffect(() => {
        if (!query.fetching && query.data && query.data.categoryADView) {
            const data = query.data.categoryADView as Category;
            setValue('name', data.name, { shouldValidate: true, shouldDirty: true });
            setValue('order', data.order, { shouldValidate: true, shouldDirty: true });
            setValue('description', data.description, { shouldValidate: true, shouldDirty: true });
        }
    }, [query, setValue]);

    const [, manageCategory] = useMutation(/* GraphQL */ `
        mutation ($id: ID, $input: CategoryInput!) {
            manageCategory(id: $id, input: $input)
        }
    `);

    const onSubmit = async (input: Form) => {
        // because html form is always a string
        input.order = +input.order;

        const result = await manageCategory({ id, input });

        if (result.error) {
            notification.error(result.error);
        } else {
            if (!id) {
                navigate(`/categories/manage/${result.data.manageCategory}`, { replace: true });
            }

            notification.success('Categoria salvata con successo');
        }
    };

    return (
        <Layout title="Gestisci categoria">
            <h2>Gestisci categoria</h2>
            <span className="italic font-bold text-xs">* Campi obbligatori</span>

            <form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
                <div className="flex">
                    <label className="font-bold w-2/12 pr-4 flex justify-end items-center" htmlFor="name">
                        Nome *
                    </label>
                    <div className="flex-1">
                        <input {...register('name', { required: true })} placeholder="Nome" className="input" />
                        {errors.name && <div className="input-error">Il nome è obbligatorio</div>}
                    </div>
                </div>
                <div className="flex mt-4">
                    <label className="font-bold w-2/12 pr-4 flex justify-end items-center" htmlFor="order">
                        Ordine *
                    </label>
                    <div className="flex-1">
                        <input {...register('order', { required: true })} type="number" placeholder="Ordine" className="input" />
                        {errors.order && <div className="input-error">L&apos;ordine è obbligatorio</div>}
                    </div>
                </div>
                <div className="flex mt-4">
                    <label className="font-bold w-2/12 pr-4 flex justify-end items-center" htmlFor="description">
                        Descrizione
                    </label>
                    <textarea {...register('description')} placeholder="Descrizione (facoltativa)" className="flex-1 input" />
                </div>

                <button type="submit" className="button vdp-green w-full mt-4">
                    Salva
                </button>
            </form>
        </Layout>
    );
};
